import React, { Component } from 'react';
import { Link, Navigate } from "react-router-dom";
import axios from 'axios';
import Item from './item';
import Loader from './../general/loader';
import backArrow from '../../assets/backArrow.png'

class Category extends Component {

    settings = this.props.Settings;

    state = {
        categories: [],
        load: false,
        back: false
    }

    async componentDidMount() {
        const {data} = await axios.get("https://manage.caviarmenu.net/api/categories/ninetynine");
        this.setState({ categories: data }, ()=>{
            this.setState({load: true});
        });
    }

    handleChange = e => {
        this.props.handleLangaugeSubmit(e);
    }

    headCover = {'backgroundImage':`url('${this.settings.introCover}')`, 'backgroundSize':'cover', 'backgroundPosition':'center'}
    categoryCover = {'backgroundSize': 'cover', 'backgroundPosition': 'center'}

    render() {
        return (

            <>
                { this.state.back && <Navigate to="/" replace={true} /> }
                <div className='category'>

                    <div className="head" style={this.headCover}>
                            <Link to={"/"}>
                                <img src={backArrow} className="backward" alt="" />
                            </Link>
                            
                            <div className="language">
                                <button type='submit' className={this.props.Language == "en"? 'activ': ''} onClick={() => this.handleChange('en')}> English </button>
                            </div>

                        <img src={this.settings.logo}  className='logo' alt="" />
                    </div>
                    <div className="info">
                        <b>{this.settings.name}</b>
                        <div className='address'>
                            <div>
                                <i className="fa-solid fa-location-pin"></i><span>{this.settings.address}</span>
                            </div>
                            <div className='phone'>
                                <i className="fa-solid fa-phone"></i> 
                                <div>
                                    <span>{this.settings.phone}</span>
                                    <span>{this.settings.phone_2}</span>
                                </div>
                            </div>
                        </div>

        
                    </div>
                    
                    <div className="category-list">
                        
                        {
                            this.state.load 
                            ?
                                this.state.categories.map( (category, index) => <Item key={index} Data = {category} Language = {this.props.Language} Index={index} Length={this.state.categories.length} ></Item>)
                            : 
                                <Loader></Loader>
                        }

                    </div>

                </div>
            </>
        );
    }
}

export default Category;